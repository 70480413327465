/* eslint-disable @typescript-eslint/no-explicit-any */
import type { MutableRefObject } from "react";
import { createContext, useContext } from "react";
import {
  action,
  computed,
  flow,
  makeAutoObservable,
  observable,
  reaction,
  runInAction,
  toJS,
} from "mobx";
import type { AxisSetExtremesEventObject } from "highcharts";
import { DateTime } from "luxon";
import posthog from "posthog-js";

import {
  blkReader,
  heatEnergyBalance,
  HOUR_MILIS,
  INTERVAL_HOURLY,
  SUBSTATION_BLOCK_TYPES as SBT,
} from "@config";
import * as CONSUMPTION from "@config/consumption";
import type { TimeSeries } from "@core/types/common";
import { debounce, getUrlParam, isValue } from "@core/utils";
import { calculateDailyAverages } from "@core/utils/calculateDailyAverages";
import type { ReaderFunction } from "@core/utils/deriveTimeSeries";
import { deriveTimeSeries } from "@core/utils/deriveTimeSeries";
import {
  generateSpeedDates,
  handleSpeedOptionChange,
  SpeedContext,
  SpeedState,
} from "@core/utils/speedUtils";
import type { rootStore } from "@stores/root_store";
import type { HighchartsBaseRef } from "@shared/ui/analytics/charts/HighchartsBase/HighchartsBase";

import { CLUSTER, SUBSTATION } from "../../Networks/Constants";
import { consumptionLogger } from "./utils";

const logger = consumptionLogger.getSubLogger({ name: "store" });
const CHART_WIDTH_OFFSET = 195;
const REFERENCE_PERIOD_STORE_ID = "referencePeriod";

type DateRange = { start: DateTime | null; end: DateTime | null };

type ChartRef = MutableRefObject<HighchartsBaseRef>;

type ChartRefs = {
  primary: ChartRef | null;
  navigator: ChartRef | null;
};

const INITIAL_DATA_RANGE = { start: null, end: null };
const DEFAULT_INTERVAL = INTERVAL_HOURLY;

function rowsInRanges({ start, end }: { start: DateTime; end: DateTime }) {
  return ({ ts }: { ts: number }) => ts >= start.toMillis() && ts <= end.toMillis();
}

/**
 * function to set Reference period to session Storage
 * store reference Period base on substation uid and network uid
 * {
 *    network_uid: {
 *      substation_uid: {
 *        start: DateTime
 *        end: DateTime
 *      }
 *    }
 * }
 */
const setRefPeriodToSessionStorage = (
  networks_uid: string,
  substation_uid: string,
  start: DateTime,
  end: DateTime
) => {
  const referencePeriodSession = sessionStorage.getItem(REFERENCE_PERIOD_STORE_ID) as any;
  const referencePeriodInSessionParsed = JSON.parse(referencePeriodSession);
  // if reference Period exist in session Storage we just update it
  if (referencePeriodInSessionParsed) {
    // Iterate through existing networks
    for (const existingNetworkUid in referencePeriodInSessionParsed) {
      // Check if network uid matches and update substations if it does
      if (existingNetworkUid === networks_uid) {
        for (const existingSubstationUid in referencePeriodInSessionParsed[existingNetworkUid]) {
          // Check if substation uid matches and update values if it does
          if (existingSubstationUid === substation_uid) {
            referencePeriodInSessionParsed[existingNetworkUid][existingSubstationUid] = {
              start,
              end,
            };
            sessionStorage.setItem(
              REFERENCE_PERIOD_STORE_ID,
              JSON.stringify(referencePeriodInSessionParsed)
            );
            return;
          }
        }
        // If network exists but substation doesn't, add it
        referencePeriodInSessionParsed[existingNetworkUid][substation_uid] = { start, end };
        sessionStorage.setItem(
          REFERENCE_PERIOD_STORE_ID,
          JSON.stringify(referencePeriodInSessionParsed)
        );
        return;
      }
    }
    // If network doesn't exist, add it with the substation
    referencePeriodInSessionParsed[networks_uid] = { [substation_uid]: { start, end } };
    sessionStorage.setItem(
      REFERENCE_PERIOD_STORE_ID,
      JSON.stringify(referencePeriodInSessionParsed)
    );
  } else {
    sessionStorage.setItem(
      REFERENCE_PERIOD_STORE_ID,
      JSON.stringify({ [networks_uid]: { [substation_uid]: { start, end } } })
    );
  }
};

export class ConsumptionStore {
  // An indicator to track if user ever fetched via "Load More Data"
  initialRun = true;

  hasPartialData = false;

  hasCompleteData?: boolean | null = null;

  needsFetch = false;

  data = {};

  averaged = false;

  flowLimiter = null;

  interval = DEFAULT_INTERVAL;

  chartRefs: ChartRefs = {
    primary: null, // MeterData Graph
    navigator: null, // FilterBar's ChartNavigator
  };

  /**
   * Immediately updated boundiries/extremes by all components from @FilterBar
   * See @ChartNavigator Component
   */
  extremes: DateRange = INITIAL_DATA_RANGE;

  prevExtremes: DateRange = INITIAL_DATA_RANGE;

  /**
   * Stores the dates for currently fetched data range
   * It will be updated only with Laad More section
   * See @FetchRangeSelector Component
   */
  fetchedRange: DateRange = INITIAL_DATA_RANGE;

  /**
   * Debounced value from @extremes
   * Its used to filter data for second and third charts with @tsavgderive @tsderive utils
   * See @SignatureDiagram and @SortedMetrics Components
   */
  readerRange: DateRange = INITIAL_DATA_RANGE;

  /**
   * Reference period
   * Its used to generate second series data for fixed range chosen
   */
  referencePeriod: DateRange = INITIAL_DATA_RANGE;

  speedOption = "";

  isSpeedButtonActive = false;

  showHeatEnergyBalance = false;

  section?: string = "consumption";

  parent: typeof rootStore;
  faults: Array<Array<string>> = [];

  constructor(parent: typeof rootStore) {
    makeAutoObservable(
      this,
      {
        chartRefs: false,
        data: observable,
        seriesData: computed,
        needsFetch: observable,
        getGraphsData: flow.bound,
        faults: observable,
      },
      { autoBind: true }
    );

    logger.debug("INITIALIZING...");
    this.parent = parent;

    // Get section from URL
    const exploreTab = getUrlParam("explore_tab");
    let defaultSection = CONSUMPTION.DEFAULT_SECTION;
    if (exploreTab !== "" && exploreTab !== CONSUMPTION.DEFAULT_SECTION) {
      defaultSection = exploreTab;
    }

    // Initialize section
    this.section = defaultSection;
    this.showHeatEnergyBalance = defaultSection === "fault_detection";

    this.reset();

    // Initialize the store
    this.fetchOnReady();
    this.listenFetchRange();
    this.listenExtremes();
  }

  fetchOnReady() {
    reaction(
      () => [this.parent.networks.ready, this.needsFetch, this.section],
      ([isReady, needsFetch, section]) => {
        if (
          // wait for network
          !isReady ||
          // do not fetch on "overview" section
          !section ||
          section === "overview"
        )
          return;
        // fetch
        if (needsFetch) void this.getGraphsData();
      },
      { fireImmediately: true }
    );
  }

  listenFetchRange() {
    reaction(
      () => [this.fetchedRange],
      ([{ start, end }]) => {
        if (!this.hasPartialData || !start || !end) return;
        logger.debug("🔄 CHANGED(fetchedRange) Sync extremes...");
        this.setExtremes(start, end);
      },
      { fireImmediately: true }
    );
  }

  listenExtremes() {
    reaction(
      () => [this.extremes],
      ([{ start, end }]) => {
        if (!end || !start) return;
        logger.debug("🔄 CHANGED(extremes) Sync readerRange and speed options...");
        this.setReaderRange(start, end);
        this.updateSpeedOption(start, end);
        this.updateChartExtremes(start, end);
      },
      { fireImmediately: true }
    );
  }

  reset = action(function reset(this: ConsumptionStore) {
    logger.debug("⏲️ Resetting store state...");
    const { networks } = this.parent;
    this.hasPartialData = false;
    this.data = {};
    this.speedOption = "";
    this.isSpeedButtonActive = false;
    this.initRefPeriod();
    // Set initial fetch range
    const networkDate = networks.CurrentHour;
    // the start of next network hour
    let end = networkDate.plus({ hour: 1 }).startOf("hour");
    // the first day and hour of 12 months back
    let start = end.minus({ month: 12 }).set({ day: 1 }).startOf("day");
    if (this.showHeatEnergyBalance) {
      logger.debug("Fault Detection tab is active. Checking URL params...");

      const urlStartDate = getUrlParam("start_date");
      const urlEndDate = getUrlParam("end_date");
      if (urlStartDate) {
        logger.debug("URL includes 'start_date' param. Setting range start: ", urlStartDate);
        start = DateTime.fromISO(urlStartDate);
      }

      if (urlEndDate) {
        logger.debug("URL includes 'end_date' param. Setting range end: ", urlEndDate);
        end = DateTime.fromISO(urlEndDate);
      }

      if (!urlStartDate && !urlEndDate) {
        logger.debug("No URL params found. Checking meteringLatestUpload...");
        const { meteringLatestUpload } = networks;
        if (meteringLatestUpload) {
          end = DateTime.fromISO(meteringLatestUpload.toISODate()).minus({ day: 1 }).endOf("day");
          start = end.minus({ week: 1 }).startOf("day");
          logger.debug(
            "meteringLatestUpload(%s) found. Using it as range start(%j) and end(%j) - 1 week",
            meteringLatestUpload.toString(),
            start,
            end
          );
        }
      }
    }
    // set initial ranges
    logger.debug("fetchedRange SET [start: %s end: %s]", String(start), String(end));
    this.fetchedRange = { start, end };
    this.extremes = { start, end };
    // trigger a fetch with new range
    this.needsFetch = true;
  });

  initRefPeriod = action(function shouldRefetch(this: ConsumptionStore) {
    const { sub, networks } = this.parent;
    const networksUid = networks.current_network?.uid;
    // Check if the ref period in the session store and use it if its
    let initialRefPeriod;
    const referencePeriodInSession = sessionStorage.getItem(REFERENCE_PERIOD_STORE_ID);
    if (referencePeriodInSession) {
      const referencePeriodInSessionParsed = JSON.parse(referencePeriodInSession);
      const substationReferencePeriod =
        referencePeriodInSessionParsed?.[networksUid!]?.[sub.current_substation];
      if (substationReferencePeriod !== undefined) {
        initialRefPeriod = {
          start: DateTime.fromISO(substationReferencePeriod.start),
          end: DateTime.fromISO(substationReferencePeriod.end),
        };
      } else {
        initialRefPeriod = INITIAL_DATA_RANGE;
      }
    } else {
      initialRefPeriod = INITIAL_DATA_RANGE;
    }
    this.referencePeriod = initialRefPeriod;
  });

  shouldRefetch = action(function shouldRefetch(this: ConsumptionStore) {
    this.initRefPeriod();
    this.hasPartialData = false;
    this.data = {};
    this.needsFetch = true;
  });

  /**
   * Registerer for main chart references.
   * This is mainly used to sync the navigator chart extremes with the main chart.
   *
   * @param {string} chartId
   * @param {ChartRef} ref
   * @memberof ConsumptionStore
   */
  registerChart = action(function registerChart(
    this: ConsumptionStore,
    chartId: "primary" | "navigator",
    ref: ChartRef
  ) {
    if (!chartId) logger.error("registerChart failed: chartId is required");

    if (this.chartRefs[chartId] || !ref) return;

    logger.debug("Registering Chart Reference: ", chartId);
    this.chartRefs[chartId] = ref;
  });

  /**
   * Change handler for @FilterBar extreme start datepicker
   *
   * @param newStartDate
   * @returns
   */
  onExtremeStartInputChange(newStartDate: DateTime) {
    const { end } = this.extremes;
    if (!newStartDate || !end || !end?.isValid) return;

    let newExtremeStart = newStartDate;
    if (newStartDate > end) {
      newExtremeStart = DateTime.fromMillis(end?.toMillis()).minus({ hours: 6 });
    }

    this.speedOption = "";
    this.setNavigatorExtremes(newExtremeStart.toMillis(), end?.toMillis());
  }

  /**
   * Change handler for @FilterBar extreme end datepicker
   * @param newEndDate
   * @returns
   */
  onExtremeEndInputChange(newEndDate: DateTime) {
    const { start } = this.extremes;
    if (!newEndDate || !start || !start?.isValid) return;

    let end = newEndDate;
    if (newEndDate < start) {
      end = DateTime.fromMillis(start.toMillis()).plus({
        hours: 6,
      });
    }

    this.speedOption = "";
    this.setNavigatorExtremes(start.toMillis(), end.toMillis());
  }

  /**
   * Change handler for @FilterBar speed options
   * @param label
   * @returns
   */
  onSpeedOptionChange = action(function onSpeedOptionChange(
    this: ConsumptionStore,
    nextOption: string
  ) {
    const current: SpeedState = {
      speedOption: this.speedOption,
      isSpeedButtonActive: this.isSpeedButtonActive,
    };
    const next = handleSpeedOptionChange(nextOption, current, this.speedDates, this.fetchedRange);

    this.speedOption = next.speedOption;
    this.isSpeedButtonActive = next.isSpeedButtonActive;
    this.setNavigatorExtremes(next.start.toMillis(), next.end.toMillis());

    logger.debug(
      "☎️[onSpeedOptionChange] %s > %s",
      current.speedOption,
      next.speedOption || "<n/a>"
    );
  });

  /**
   * Change handler for explorer panel sections
   *
   * [ Overview | Consumption | Fault Detection ]
   *
   * @param nextSection
   * @returns
   */
  onSectionChange = action(function onSectionChange(this: ConsumptionStore, nextSection: string) {
    const { ui } = this.parent;
    const oldSection = this.section;
    // this special chart must only be processed on the fault detection tab
    this.showHeatEnergyBalance = nextSection === "fault_detection";
    // reset the interval to default(hourly)
    this.interval = DEFAULT_INTERVAL;
    this.averaged = false;
    if (oldSection === nextSection) return;

    logger.debug("[onSectionChange] '%s' > '%s'", oldSection, nextSection);
    this.section = nextSection;
    this.reset();
    // Charts must be re-registed on section change
    this.chartRefs = { primary: null, navigator: null };
    const resourceType = ui.is_subsummary_open ? SUBSTATION : CLUSTER;

    posthog.capture("explore_section_change", {
      "Substation/Cluster Explore": {
        fromSection: oldSection,
        toSection: nextSection,
        resourceType: resourceType,
      },
    });
  });

  /**
   * Change handler for @FilterBar interval dropdown
   * @param nextInterval
   * @returns
   */
  onIntervalChange = action(function onIntervalChange(
    this: ConsumptionStore,
    nextInterval: string
  ) {
    logger.debug("onIntervalChange(%s) ", nextInterval);
    this.interval = nextInterval;
    this.averaged = nextInterval === CONSUMPTION.INTERVAL_DAILY;

    CONSUMPTION.columns.forEach((col) => {
      if (col.key === "heat") {
        col.name =
          nextInterval === CONSUMPTION.INTERVAL_DAILY
            ? "text_average_heat_power"
            : "text_heat_power";
      }
    });
  });

  /**
   * Handler for Highcharts navigator extremes change event
   * This ensures that the navigator extremes are always in sync with the chart extremes
   *
   * This is usually triggers when the user drags the navigator
   * and @setNavigatorExtremes is called
   * or when the user clicks on the "load more data" button
   * or when the user changes the interval
   * or when the user changes the speed option
   * or when the user changes the date range
   *
   * @param event AxisSetExtremesEventObject
   * @returns
   * @see https://api.highcharts.com/highstock/xAxis.events.setExtremes
   * @see https://api.highcharts.com/highstock/Axis.setExtremes
   * @see https://api.highcharts.com/highstock/AxisSetExtremesEventObject
   *
   */
  onNavigatorExtremesUpdated = action(function afterSetNavigatorExtremes(
    this: ConsumptionStore,
    event: AxisSetExtremesEventObject
  ) {
    const chart = this.chartRefs?.primary?.current?.chart?.xAxis?.[0];
    if (!chart || typeof chart.setExtremes !== "function") {
      return;
    }

    logger.debug("onNavigatorExtremesUpdated()");

    let start = DateTime.fromMillis(event.min);
    let end = DateTime.fromMillis(event.max);

    // Hourly interval is active
    if (!this.averaged) {
      // We dont care anything below the hour
      start = start.set({
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      end = end.set({
        minute: 0,
        second: 0,
        millisecond: 0,
      });
    } else {
      // during the `Daily` the last record is at 23:00
      end = end.set({ hour: 23 });
    }

    if (this.fetchedRange.start && start < this.fetchedRange.start) start = this.fetchedRange.start;
    // Update the store
    this.setExtremes(start, end);
  });

  updateChartExtremes(start: DateTime, end: DateTime) {
    const chart = this.chartRefs?.primary?.current?.chart?.xAxis?.[0];
    if (chart && typeof chart.setExtremes === "function") {
      chart.setExtremes(start.toMillis(), end.toMillis(), true, false);
    }
  }

  /**
   * A helper to directly update the Highcharts navigator extremes
   * This will trigger onNavigatorExtremesUpdated on chart updates its extremes
   *
   * @param newSpeedOption
   * @returns
   */
  setNavigatorExtremes(startTs: number, endTs: number) {
    const navigatorRef = this.chartRefs.navigator?.current;
    const navigatorChart = navigatorRef?.chart?.xAxis[0];
    if (!navigatorChart) return;
    logger.debug("setNavigatorExtremes(%s, %s) ", startTs, endTs);
    navigatorChart.setExtremes(startTs, endTs, true, false);
  }

  /**
   * Set the range of data that is currently being displayed.
   *
   * @param {DateTime} start
   * @param {DateTime} end
   * @memberof ConsumptionStore
   */
  setExtremes = action(function setExtremes(
    this: ConsumptionStore,
    start: DateTime,
    end: DateTime
  ) {
    logger.debug("setExtremes(%s, %s) ", String(start), String(end));
    this.extremes = { start, end };
  });

  /**
   * Set the range of data that has been fetched from the server.
   * This is used to determine if we need to fetch more data.
   *
   * @param {DateTime} start
   * @param {DateTime} end
   * @memberof ConsumptionStore
   */
  setFetchedRange = action(function setFetchedRange(
    this: ConsumptionStore,
    start: DateTime,
    end: DateTime,
    refetch = false
  ) {
    logger.debug("setFetchedRange(%s, %s) ", String(start), String(end));
    this.needsFetch = true;
    this.initialRun = false;
    this.flowLimiter = null;
    this.hasPartialData = false;
    this.data = {};
    // set the new range
    this.fetchedRange.start = start;
    this.fetchedRange.end = end;
    this.referencePeriod = INITIAL_DATA_RANGE;

    if (refetch) {
      void this.getGraphsData();
    }
  });

  // this replicates the same behavior as networks.dtFromYM
  // which will enable "tsderive" to include last point as the chart
  shiftRangeEnd(date: DateTime): DateTime {
    let endDate = date.plus({ seconds: 1 });
    if (this.averaged) endDate = date.plus({ day: 1 });
    return endDate;
  }

  /**
   * Set the reader range
   *
   * @param {DateTime} start
   * @param {DateTime} end
   * @returns
   *
   * @memberof ConsumptionStore
   */
  setReaderRange = action(
    debounce(function setReaderRange(this: ConsumptionStore, start: DateTime, end: DateTime) {
      logger.debug("debounce(setReaderRange(%s, %s)) ", String(start), String(end));
      this.readerRange = {
        start,
        end,
      };
    }, 200)
  );

  setReferencePeriod(start: DateTime | null, end: DateTime | null) {
    let rangeEnd = end;
    if (end?.isValid) rangeEnd = end.endOf("day").startOf("hour");
    logger.debug("setReferencePeriod(%s, %s) ", String(start), String(rangeEnd));
    this.referencePeriod = toJS({ start, end: rangeEnd });
    const { sub, networks } = this.parent;
    setRefPeriodToSessionStorage(
      networks.current_network!.uid,
      sub.current_substation,
      this.referencePeriod.start!,
      this.referencePeriod.end!
    );
  }

  updateSpeedOption(start: DateTime, end: DateTime) {
    const activeSpeedOption = this.findActiveSpeedOption(start, end);
    runInAction(() => {
      this.speedOption = activeSpeedOption ?? "";
      this.isSpeedButtonActive = !!activeSpeedOption;
    });
  }

  findActiveSpeedOption(start: DateTime, end: DateTime): string | undefined {
    return Object.entries(this.speedDates).find(
      ([, speedDate]) =>
        speedDate.start.ordinal === start.ordinal &&
        speedDate.end.ordinal === end.ordinal &&
        speedDate.start.year === start.year &&
        speedDate.end.year === end.year
    )?.[0];
  }

  get speedDates() {
    let startHour = 8;
    let endHour = 9;
    if (this.showHeatEnergyBalance || !this.initialRun) {
      startHour = 0;
      endHour = 0;
    }
    const context: SpeedContext = {
      fetchedRange: this.fetchedRange,
      startHour,
      endHour,
    };
    logger.debug("Rebuilding speed dates, with range: %j", context);
    return generateSpeedDates(context);
  }

  get reader(): ReaderFunction {
    if (!this.hasPartialData || !this.data) return null;

    const filters = [
      ["name", ["meter", "ts"], "ts"],
      ["name", ["meter", "heat_energy"], "heat"],
      ["name", ["meter", "volume"], "vol"],
      ["name", ["meter", "supplytemp"], "st"],
      ["name", ["meter", "returntemp"], "rt"],
      // @ts-expect-error later
      ["derive", (r) => r.st - r.rt, "dt"],

      ["name", ["weather", "t"], "outdoor"],
    ];

    if (this.showHeatEnergyBalance) {
      filters.push(["derive", (r) => heatEnergyBalance(r.heat, r.vol, r.st, r.rt), "heb"]);
    }

    // @ts-expect-error Types are hard
    return blkReader(this.data, filters);
  }

  get seriesData(): [{ ts: number; [key: string]: any }] {
    if (!this.hasPartialData) return [{ ts: 0 }];

    const start = this.fetchedRange?.start?.toMillis();
    const end = this.fetchedRange?.end?.plus({ hour: 1 })?.toMillis();
    const delta = HOUR_MILIS;
    const columns = CONSUMPTION.columns.filter(
      (col) => !(col.key === "heb" && !this.showHeatEnergyBalance)
    );
    const dataFns: any[] = [];
    [...columns, { key: "outdoor" }, { key: "ts" }].forEach((col) => {
      dataFns.push([
        col.key,
        (row: Record<string, any>, rarr: any[]) => {
          if (isValue(row[col.key])) {
            rarr.push(row[col.key]);
          } else {
            rarr.push(null);
          }
        },
      ]);
    });
    const derivedData = deriveTimeSeries({
      reader: this.reader,
      start,
      end,
      delta,
      fns: dataFns,
    }).filter((row) => row.ts);

    this.needsFetch = false;

    return [...derivedData] as any;
  }

  get seriesDataAveraged() {
    const data: TimeSeries[] = calculateDailyAverages(this.seriesData);

    return [...data] as any;
  }

  get activeData() {
    return this.averaged ? this.seriesDataAveraged : this.seriesData;
  }

  get seriesInRanges() {
    const { start, end } = this.readerRange;
    if (!this.activeData || !start || !end) return [];

    return this.activeData.filter(rowsInRanges({ start, end }));
  }

  get seriesInExtremes() {
    const { start, end } = this.extremes;
    if (!this.activeData || !start || !end) return [];

    return this.activeData.filter(rowsInRanges({ start, end }));
  }

  get seriesInReferencePeriod() {
    const { start, end } = this.referencePeriod;
    if (!this.activeData || !start || !end) return [];

    return this.activeData.filter(rowsInRanges({ start, end }));
  }

  get chartWidth() {
    const { ui } = this.parent;
    return ui.main_area + CHART_WIDTH_OFFSET;
  }

  getGraphsData = flow(function* getGraphsData(this: ConsumptionStore) {
    if (!this.fetchedRange.start || !this.fetchedRange.end) {
      logger.error("getGraphsData() - no fetchedRange");
      throw new Error("No fetchedRange set. Cannot fetch data!");
    }

    if (!this.needsFetch) return;
    logger.debug("getGraphsData()");

    const { sub, networks, newapi, ui } = this.parent;
    const lastProcessedMonth = networks.lpMonth;
    const resourceType = ui.is_subsummary_open ? SUBSTATION : CLUSTER;
    const resourceId =
      resourceType === SUBSTATION ? sub.current_substation : sub.current_cluster.id;

    const substationOrClusterData = yield newapi.getInfoBlocksV4({
      network_id: networks.current_network?.uid,
      resource_id: resourceId,
      resource_type: resourceType,
      block_names: [
        SBT.pricing.to_block_name(),
        SBT.core.to_block_name({
          year: lastProcessedMonth.year,
          month: lastProcessedMonth.month,
        }),
        SBT.location.to_block_name(),
      ],
    });
    const dataReader = blkReader(substationOrClusterData, [
      ["name", [SBT.pricing.to_block_name(), "flow_limit"], "flow_limit"],
      ["name", [SBT.location.to_block_name(), SBT.location.col.weather_coordinates], "coords"],
    ]);
    this.flowLimiter = dataReader(sub.current_substation)?.flow_limit;

    let [weather, meterArr] = [null, []];
    const dataReaderCoords = dataReader(sub.current_substation)?.coords;
    const weatherCoords = dataReaderCoords || this.parent.networks.coordinates;

    if (!dataReaderCoords) {
      this.parent.notifications.warning(
        "The coordinates are missing for this substation. Networks coordinates are used instead as a fall back."
      );
    }

    try {
      logger.debug("getGraphsData() - Fetching data...");
      [weather, meterArr] = yield Promise.all([
        newapi.getWeatherDataV4({
          resource_id: resourceId,
          resource_type: resourceType,
          network_id: networks.current_network?.uid,
          coordinates: weatherCoords,
          date_min: this.fetchedRange.start.startOf("day"),
          // loading additional data for daily interval
          date_max: this.fetchedRange.end.plus({ day: 1 }).endOf("day"),
        }),
        newapi.getMeterDataV4({
          resource_uid: resourceId,
          resource_type: resourceType,
          network_id: networks.current_network?.uid,
          ts_start: this.fetchedRange.start.startOf("day"),
          ts_end: this.fetchedRange.end.endOf("day").plus({ hour: 1 }),
          stage: "clean",
          meter: "primary",
          components: ["heat_energy", "volume", "supplytemp", "returntemp"],
          faults: true,
        }),
      ]);
    } catch (err: any) {
      this.parent.notifications.error("Network Error! Cannot fetch details.", err.error);
      this.data = {};
      this.hasPartialData = false;
      return;
    }

    let meterData;
    // if on substations, split the array into weather and meter data
    if (resourceType === SUBSTATION) {
      meterData = meterArr[0];
      this.faults = meterArr[1];
    } else {
      meterData = meterArr;
    }

    this.data = { weather, meter: meterData };
    // Faults dont contribute to the data completeness, they are part of meter data
    this.hasPartialData = !!weather || !!meterData;
    this.hasCompleteData = weather && meterData;
  });
}

export const StateContext = createContext<ConsumptionStore | object>({});

export function useConsumption() {
  const consumption = useContext(StateContext);
  window.consumptionStore = consumption;
  return consumption as ConsumptionStore;
}
