/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from "react";
import { Box, hexToRgb } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts/highstock";
import hcBoost from "highcharts/modules/boost";
import { DateTime } from "luxon";

hcBoost(Highcharts);

type ChartNavigatorProps = {
  data: { [key: string]: any } | undefined;
  dataColumn: string | undefined;
  range: {
    start: DateTime;
    end: DateTime;
  };
  chartRef: any;
  afterSetExtremes?: (extremes: { min: number; max: number }) => void;
  chartOptions?: Highcharts.Options;
};

const defaultChartOptions: Highcharts.Options = {
  global: {
    useUTC: false,
  },
  plotOptions: {
    series: {
      animation: false,
      turboThreshold: 5000,
      enableMouseTracking: false,
      stickyTracking: false,
    },
  },
};

// Helper function to normalize time to hour boundary
const normalizeToHourBoundary = (dateTime: DateTime): DateTime => {
  return dateTime.set({ minute: 0, second: 0, millisecond: 0 });
};

export const ChartNavigator = ({
  data = [],
  dataColumn = "outdoor",
  range = { start: null, end: null },
  chartRef,
  afterSetExtremes,
  chartOptions = defaultChartOptions,
}: ChartNavigatorProps) => {
  const theme = useTheme();
  // Normalize start and end times to hour boundaries
  const normalizedStart = normalizeToHourBoundary(range.start);
  const normalizedEnd = normalizeToHourBoundary(range.end);

  const startDateTs = normalizedStart.toMillis();
  const endDateTs = normalizedEnd.toMillis();

  // Memoize series data to prevent unnecessary recalculations
  const series = useMemo(() => {
    const seriesData = data.map((row) => row?.heat ?? row?.[dataColumn] ?? 0);
    return [
      {
        type: "spline",
        name: "",
        pointStart: startDateTs,
        pointInterval: 3600 * 1000, // Hourly data (1 hour in milliseconds)
        data: seriesData,
        lineWidth: 0,
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },
        yAxis: 0,
        enableMouseTracking: false,
        animation: false,
        turboThreshold: 5000,
        boostThreshold: 1000,
      },
    ];
  }, [data, dataColumn, startDateTs]);

  // Wrap afterSetExtremes to ensure hour boundaries
  const handleAfterSetExtremes = useMemo(() => {
    if (!afterSetExtremes) return undefined;

    return (e: Highcharts.AxisSetExtremesEventObject) => {
      const normalizedMin = normalizeToHourBoundary(DateTime.fromMillis(e.min));
      const normalizedMax = normalizeToHourBoundary(DateTime.fromMillis(e.max));

      afterSetExtremes({
        min: normalizedMin.toMillis(),
        max: normalizedMax.toMillis(),
      });
    };
  }, [afterSetExtremes]);

  // Memoize chart options to prevent unnecessary re-renders
  const options = useMemo(
    () => ({
      ...chartOptions,
      boost: {
        enabled: true,
        seriesThreshold: 1,
        useGPUTranslations: true,
        usePreallocated: true,
      },
      scrollbar: {
        barBorderRadius: 0,
        barBorderWidth: 1,
        barBackgroundColor: theme.palette.secondary.main,
        barBorderColor: theme.palette.common.white,
        buttonsEnabled: true,
        margin: 0,
        rifleColor: theme.palette.primary.main,
        trackBackgroundColor: theme.palette.common.white,
        trackBorderRadius: 0,
        liveRedraw: false,
      },
      chart: {
        id: "navigator",
        height: 25,
        spacing: 0,
        backgroundColor: theme.palette.primary.light,
        borderColor: theme.palette.text.primary,
        animation: false,
        zooming: {
          mouseWheel: false,
        },
      },
      navigator: {
        enabled: true,
        height: 15,
        outlineColor: theme.palette.text.primary,
        maskFill: hexToRgb(`${theme.palette.graph.orange}80`),
        xAxis: {
          labels: {
            style: {
              color: theme.palette.common.white,
            },
          },
          ordinal: false,
          minTickInterval: 3600 * 1000, // Force 1-hour minimum intervals
        },
        series: {
          lineWidth: 0,
          animation: false,
          pointInterval: 3600 * 1000, // Ensure navigator series also uses hourly intervals
        },
      },
      navigation: {
        buttonOptions: {
          enabled: false,
        },
      },
      title: {
        text: "",
      },
      rangeSelector: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      yAxis: {
        title: null,
        gridLineWidth: 0,
        visible: false,
        labels: {
          enabled: false,
        },
      },
      xAxis: {
        type: "datetime",
        labels: {
          enabled: false,
        },
        tickLength: 0,
        lineWidth: 0,
        minRange: 3600 * 1000, // Minimum range of 1 hour
        minTickInterval: 3600 * 1000, // Force 1-hour minimum intervals
        min: startDateTs,
        max: endDateTs,
        ordinal: false,
        events: {
          afterSetExtremes: handleAfterSetExtremes,
        },
      },
      series,
      plotOptions: {
        series: {
          animation: false,
          turboThreshold: 5000,
          enableMouseTracking: false,
          stickyTracking: false,
          pointInterval: 3600 * 1000, // Ensure all series use hourly intervals
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              opacity: 1,
            },
          },
        },
      },
    }),
    [chartOptions, theme, startDateTs, endDateTs, series, handleAfterSetExtremes]
  );

  if (!range?.start || !range?.end || !data.length) return null;

  return (
    <Box
      width="100%"
      sx={{
        borderTop: `${theme.palette.grey["50"]} 1px solid`,
        borderBottom: `${theme.palette.grey["50"]} 1px solid`,
      }}
    >
      <HighchartsReact
        ref={chartRef}
        highcharts={Highcharts}
        constructorType="stockChart"
        options={options}
      />
    </Box>
  );
};
