import { action, runInAction } from "mobx";
import { useLocalObservable } from "mobx-react";

import {
  blkReader,
  CORE_BLOCK as CORE_YEARLY_BLOCK,
  CUSTOMER_BLOCK,
  INSTALL_ADDRESS_BLOCK,
  SUBSTATION_BLOCK_TYPES,
} from "@conf/blocks";

import useMobxSelector from "../../../hooks/useMobxSelector";
import useReaction from "../../../hooks/useReaction";
import useStores from "../../../hooks/useStores";
import { CLUSTER, FAVORITE, LIST, NETWORK_SUBSTATION } from "../constant";

const useSubExplore = ({ type }) => {
  const { networks, preferences, ui, newapi, rootStore, sub } = useStores();
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const local = useLocalObservable(() => ({
    inputText: "",
    dataFetched: false,
    substations: {},
    substationsUid: [],
    clusters: [],
    lpYear: networks.lpYear.year,
    state: { currentTab: LIST, force: false },

    // computed
    get colSpecs() {
      return type === NETWORK_SUBSTATION
        ? [
            [
              "name",
              [
                SUBSTATION_BLOCK_TYPES.customer.to_block_name(),
                SUBSTATION_BLOCK_TYPES.customer.col.name,
              ],
              "customer",
            ],
            [
              "name",
              [
                SUBSTATION_BLOCK_TYPES.install_address.to_block_name(),
                SUBSTATION_BLOCK_TYPES.install_address.col.street,
              ],
              "street",
            ],
            [
              "name",
              [
                SUBSTATION_BLOCK_TYPES.install_address.to_block_name(),
                SUBSTATION_BLOCK_TYPES.install_address.col.coordinates,
              ],
              "coordinates",
            ],
            [
              "name",
              [
                SUBSTATION_BLOCK_TYPES.core.to_block_name({ year: local.lpYear }),
                SUBSTATION_BLOCK_TYPES.core.col.heat_energy_sum,
              ],
              "heat",
            ],
          ]
        : [
            [
              "name",
              [
                SUBSTATION_BLOCK_TYPES.core.to_block_name({ year: local.lpYear }),
                SUBSTATION_BLOCK_TYPES.core.col.heat_energy_sum,
              ],
              "heat",
            ],
          ];
    },

    // eslint-disable-next-line sonarjs/cognitive-complexity
    get filteredList() {
      if (!local.dataFetched) {
        return [];
      }
      const currentNetwork = networks.current_network;
      const currentNetworkList = preferences.fav_subs.get(currentNetwork.uid);
      let activeNetwork =
        type === NETWORK_SUBSTATION ? networks.active_substations : networks.active_clusters;
      const block = type === NETWORK_SUBSTATION ? local.substations : local.clusters;
      const blockReader = blkReader(block, local.colSpecs);
      let showSubs = new Map();

      if (local.state.currentTab === FAVORITE) {
        if (currentNetworkList) {
          // eslint-disable-next-line no-restricted-syntax
          for (const aSub of activeNetwork.keys()) {
            if (currentNetworkList.has(aSub)) {
              showSubs.set(aSub, activeNetwork.get(aSub));
            }
          }
        }
        activeNetwork = showSubs;
      }

      showSubs = new Map();
      if (local.inputText.length > 0) {
        const iText = local.inputText.toLowerCase();
        if (type === NETWORK_SUBSTATION) {
          // eslint-disable-next-line no-restricted-syntax
          for (const [subUid, subName] of activeNetwork) {
            const det = blockReader(subUid);
            if (subName.toLowerCase().includes(iText)) {
              showSubs.set(subUid, subName);
              // eslint-disable-next-line no-continue
              continue;
            }
            if (
              (det.customer &&
                typeof det.customer === "string" &&
                det.customer.toLowerCase().includes(iText)) ||
              (det.street && det.street.toLowerCase().includes(iText))
            ) {
              showSubs.set(subUid, subName);
            }
          }
          activeNetwork = showSubs;
        }
        if (type === CLUSTER) {
          // eslint-disable-next-line no-restricted-syntax
          for (const [subUid, subName] of activeNetwork) {
            if (
              (subName && subName.toLowerCase().includes(iText)) ||
              (subUid && subUid.toLowerCase().includes(iText))
            ) {
              showSubs.set(subUid, subName);
            }
          }
          activeNetwork = showSubs;
        }
      }

      return type === NETWORK_SUBSTATION
        ? Array.from(activeNetwork.keys())
            .map((indexValue) => ({ ...blockReader(indexValue), indexValue }))
            .sort(
              (a, b) =>
                !a.customer - !b.customer ||
                +(a.customer > b.customer) ||
                -(a.customer < b.customer)
            )
            .map((v) => v.indexValue)
        : Array.from(activeNetwork.keys())
            .map((indexValue) => ({
              ...blockReader(indexValue),
              indexValue,
            }))
            .map((v) => v.indexValue);
    },

    get totalCount() {
      return local.filteredList.length || 0;
    },

    get filteredLength() {
      return networks.active_substations.size;
    },

    handleTabChange: action((e, newTab) => {
      local.state.currentTab = newTab;
    }),

    onInputChange: action((event) => {
      local.inputText = event.target.value;
    }),

    handleClearListInner: action(() => {
      const currentNetwork = networks.current_network;
      preferences.clearFavorites(currentNetwork.uid);
      local.state.force = false;
    }),

    handleClearList: action(() => {
      local.state.force = true;
    }),

    handleCancelClearList: action(() => {
      local.state.force = false;
    }),

    fetchInfoBlock: async ({ resourceId, blockNames, resourceType }) =>
      newapi.getInfoBlocksV4({
        resource_type: resourceType,
        resource_id: resourceId,
        network_id: networks.current_network.uid,
        block_names: blockNames,
      }),

    fetchClusterSubstations: async (clusterUid) =>
      rootStore.utfapi.getClusterSubstations({ clusterUid }),

    fetchSubstations: async () => {
      runInAction(() => {
        local.dataFetched = false;
      });
      const infoBlocks = await local.fetchInfoBlock({
        resourceId: networks.current_network.uid,
        resourceType: NETWORK_SUBSTATION,
        blockNames: [
          INSTALL_ADDRESS_BLOCK.to_block_name(),
          CUSTOMER_BLOCK.to_block_name(),
          CORE_YEARLY_BLOCK.to_block_name({ year: local.lpYear }),
        ],
      });

      if (infoBlocks) {
        runInAction(() => {
          local.substations = infoBlocks;
          local.dataFetched = true;
        });
      }
    },

    fetchAllCluster: async () => {
      runInAction(() => {
        local.dataFetched = false;
      });

      const activeClusters = Array.from(networks.active_clusters);
      const clusters = [];

      activeClusters.forEach((cluster) => clusters.push({ uid: cluster[0], name: cluster[1] }));

      runInAction(() => {
        local.dataFetched = true;
        local.clusters = clusters;
      });
    },

    fetchSingleCluster: async () => {
      const clusterSubstations = await local.fetchClusterSubstations(sub.current_cluster.id);
      if (clusterSubstations) {
        runInAction(() => {
          local.substationsUid = clusterSubstations.substations;
        });
      }
    },
  }));

  // fetch substation
  useReaction(
    () => [
      ui.is_cluster_summary_open,
      ui.is_substation_model_open,
      ui.is_subdetail_open,
      networks.current_network.uid,
    ],
    async ([isClusterSummaryOpen, isSubstationPanelOpen, isSubstationDetailOpen]) => {
      if (isClusterSummaryOpen || isSubstationPanelOpen || isSubstationDetailOpen) {
        await local.fetchSubstations();
      }
    },
    { fireImmediately: true }
  );

  // fetch cluster
  useReaction(
    () => [ui.is_cluster_model_open, networks.active_clusters],
    async ([isClusterPanelOpen]) => {
      if (isClusterPanelOpen) {
        await local.fetchAllCluster();
      }
    },
    { fireImmediately: true }
  );

  useReaction(
    () => [sub.current_cluster],
    async ([currentCluster]) => {
      if (currentCluster) {
        await local.fetchSingleCluster();
      }
    },
    { fireImmediately: true }
  );

  return useMobxSelector(() => ({
    substations: local.substations,
    inputText: local.inputText,
    clusters: local.clusters,
    dataFetched: local.dataFetched,
    colSpecs: local.colSpecs,
    filteredList: local.filteredList,
    state: local.state,
    totalCount: local.totalCount,
    filteredLength: local.filteredLength,
    substationsUid: local.substationsUid,
    handleTabChange: local.handleTabChange,
    onInputChange: local.onInputChange,
    handleClearListInner: local.handleClearListInner,
    handleClearList: local.handleClearList,
    handleCancelClearList: local.handleCancelClearList,
  }));
};

export default useSubExplore;
