import { TOOLBOX_DEFAULT_OPTIONS, TOOLBOX_OPTION_REFERENCE } from "./chartToolbox";

export const REF_PREFIX = "ref_";
export const INTERVAL_HOURLY = "1h";
export const INTERVAL_DAILY = "24h";
export const DATETIME_FORMAT_YYYY_MM_DD_HH_MM = "yyyy-MM-dd, HH:mm";
export const DATETIME_FORMAT_YYYY_MM_DD = "yyyy-MM-dd";

export const INTERVAL_OPTIONS = new Map([
  [INTERVAL_HOURLY, "text_hourly"],
  [INTERVAL_DAILY, "text_daily"],
]);

type TimeOption = {
  label: string;
  type: "week" | "month" | "months" | "year";
  value: number;
  disableMonth: number;
};

const createTimeOption = (
  period: string,
  type: TimeOption["type"],
  value: number,
  disableMonth: number
): TimeOption => ({
  label: `text_last_${period}`,
  type,
  value,
  disableMonth,
});

export const SPEED_OPTIONS: TimeOption[] = [
  createTimeOption("week", "week", 1, 0.25),
  createTimeOption("month", "month", 1, 1),
  createTimeOption("3_months", "months", 3, 3),
  createTimeOption("year", "year", 1, 12),
];

export const DIAGRAM_SORT_OPTIONS = new Map([
  ["heat", "text_heat_power"],
  ["vol", "text_flow"],
  ["st", "text_supply_temperature"],
  ["rt", "text_return_temperature"],
  ["dt", "text_delta_temperature"],
]);

type ColorVariant = "main" | "light";

type ColorPair = [string, ColorVariant];

type Column = {
  key: string;
  name: string;
  suffix: string;
  showInLegend: boolean;
  color: ColorPair;
  lineColor: ColorPair;
};

const createColorPairs = (color: string): { color: ColorPair; lineColor: ColorPair } => ({
  color: [color, "main"],
  lineColor: [color, "light"],
});

// Base column properties
const createColumn = (key: string, name: string, suffix: string, color: string): Column => ({
  key,
  name: `text_${name}`,
  suffix,
  showInLegend: true,
  ...createColorPairs(color),
});

// Column definitions
export const columns: Column[] = [
  createColumn("heat", "heat_power", "kW", "orange"),
  createColumn("vol", "flow", "m³/h", "green"),
  createColumn("st", "supply_temperature", "°C", "pink"),
  createColumn("rt", "return_temperature", "°C", "blue"),
  createColumn("dt", "delta_temperature", "°C", "teal"),
];

export const TOOLBOX_OPTIONS_WITH_REFERENCE = {
  ...TOOLBOX_DEFAULT_OPTIONS,
  [TOOLBOX_OPTION_REFERENCE]: "action_reference",
};
