/* eslint-disable react/jsx-props-no-spreading */
import { DateTime } from "luxon";

import { DATETIME_FORMAT_DEFAULT } from "@config";

import DatePicker, { UTFDateTimeFieldProps } from "../UtfDatePicker/DatePicker";

type FilterBarDatePickerProps = {
  variant?: string;
  minDate?: DateTime;
  maxDate?: DateTime;
  views?: string[];
  value: DateTime | null;
  onChange: (date: DateTime) => void;
  format?: string | null;
  disabled?: boolean;
  classname?: string | null;
  id?: string;
} & UTFDateTimeFieldProps;

export const FilterBarDatePicker = ({
  variant = "standard",
  minDate,
  maxDate,
  views = ["year", "month", "day"],
  value,
  onChange,
  format,
  disabled = false,
  classname = null,
  id = "filter-bar",
  ...props
}: FilterBarDatePickerProps) => {
  const { inputProps, ...restProps } = props;

  return (
    <DatePicker
      minDate={minDate}
      maxDate={maxDate}
      closeOnSelect={false}
      ampm={false}
      views={views}
      value={value}
      onChange={onChange}
      disabled={disabled}
      format={format ?? DATETIME_FORMAT_DEFAULT}
      className={classname}
      inputProps={{
        variant,
        helperText: "",
        InputProps: {
          "data-testid": `date-input__${id}`,
        },
        ...inputProps,
        sx: {
          "& .MuiInput-root": {
            border: "1px solid currentColor",
          },
          ...inputProps?.sx,
        },
      }}
      slotProps={{
        popper: {
          placement: "top",
        },
      }}
      {...restProps}
    />
  );
};
