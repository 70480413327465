/* eslint-disable sonarjs/no-duplicate-string, react/jsx-props-no-spreading */
import { Box, Tab, Tabs } from "@mui/material";
import { styled } from "@mui/styles";
import { faLockKeyhole } from "@fortawesome/pro-light-svg-icons";

import { FontAwesomeSvgIcon } from "./FontAwesomeSvgIcon";

const LockIcon = () => {
  return (
    <Box
      data-testid="tab-lock-icon"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: "0px !important",
        py: 0.5,
      }}
    >
      <FontAwesomeSvgIcon icon={faLockKeyhole} sx={{ fontSize: 13 }} />
    </Box>
  );
};

export function UtfTab({ isLocked = false, ...props }) {
  return (
    <Tab
      disableRipple
      data-testid={`tab-${props.value}`}
      {...props}
      icon={
        isLocked && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <LockIcon />
          </Box>
        )
      }
      iconPosition="start"
    />
  );
}

export const UtfTabs = styled((props) => <Tabs {...props} />)(
  () => ({
    minHeight: 40,
  }),
  { name: "UtfTabs" }
);
