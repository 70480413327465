import FlagIcon from "@mui/icons-material/Flag";

export function getFaultLevel(type: string, faults: string[] = []): JSX.Element | null {
  // faults is an array eg ["fv", "ft", "fe"]
  // fv = fault_flow
  // ft = fault_supplytemp_dipp
  // fe = fault_return_temperature
  // Return the highest priority fault
  if (faults.includes(type)) {
    return <FlagIcon color="error" />;
  }
  return null;
}

export type FaultType = "fv" | "ft" | "fe";

export const VALID_FLAGS: FaultType[] = ["fv", "ft", "fe"];

export const colorMapping = {
  fv: {
    color: "rgba(0, 128, 0, 0.1)",
    borderColor: "rgba(0, 128, 0, 0.5)",
    flagColor: "rgb(0, 128, 0)",
  },
  ft: {
    color: "rgba(128, 0, 128, 0.1)",
    borderColor: "rgba(128, 0, 128, 0.5)",
    flagColor: "rgb(128, 0, 128)",
  },
  fe: {
    color: "rgba(0, 0, 255, 0.1)",
    borderColor: "rgba(0, 0, 255, 0.5)",
    flagColor: "rgb(0, 0, 255)",
  },
};

// Map for ["returntemp", "flow", "supplytemp_dipp"] to ["fe", "fv", "ft"] respectively.
export const FAULT_MAPPING = {
  Returntemp: "fe",
  Flow: "fv",
  supplytemp_dipp: "ft",
};
