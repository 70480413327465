import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, List, Typography } from "@mui/material";
import { faMessageExclamation, faMessageQuestion } from "@fortawesome/pro-light-svg-icons";

import FEATURE from "@conf/feature_access";
import { isFeatureFlagOn } from "@core/utils/isFeatureFlagOn";
import { useInject } from "@hooks";
import { FontAwesomeSvgIcon } from "@shared/ui/FontAwesomeSvgIcon";

import { routes } from "../../routes";
import { SideBarNestedItem } from "./SideBarNestedItem";

const optionals = isFeatureFlagOn("TEST_ADMIN") || isFeatureFlagOn("BLOCK_ADMIN");
const optionalRoutes = {
  route: "",
  access: [],
  text: "Optional Test Pages",
  Icon: routes.testing.pageIcon,
  children: [
    {
      route: "testing",
      access: [],
      text: "Optional Test Pages",
    },
  ],
};
if (isFeatureFlagOn("TEST_ADMIN")) {
  optionalRoutes.children.push({
    route: "test_admin",
    access: [],
    text: "Test Admin",
  });
}
if (isFeatureFlagOn("BLOCK_ADMIN")) {
  optionalRoutes.children.push({
    route: "block_admin",
    access: [],
    text: "Block Admin",
  });
}

function FeedbackIcon() {
  return <FontAwesomeSvgIcon icon={faMessageExclamation} />;
}

function SupportIcon() {
  return <FontAwesomeSvgIcon icon={faMessageQuestion} />;
}

export function NavDrawer() {
  const { session, ui } = useInject("session", "ui");
  const { t } = useTranslation(["navigation"]);

  const getMeteringRoutes = useMemo(() => {
    const meteringRoutes = {
      route: "",
      text: t("nav_metering.name"),
      access: [FEATURE.metering],
      Icon: routes.metering.pageIcon,
      children: [
        {
          route: "metering",
          text: t("nav_metering.sub.sub_nav_metering_dashboard"),
          access: [FEATURE.metering],
        },
      ],
    };

    meteringRoutes.children.push({
      route: "fault_detection",
      text: t("nav_metering.sub.sub_nav_fault_detection"),
      access: [FEATURE.fault_detection],
    });

    return meteringRoutes;
  }, [t]);

  const getProductionRoutes = useMemo(
    () => ({
      route: "",
      text: t("nav_production.name"),
      access: [FEATURE.production],
      Icon: routes.production.pageIcon,
      children: [
        {
          route: "production",
          text: t("nav_production.sub.sub_nav_production_dashboard"),
          access: [FEATURE.production],
        },
        {
          route: "forecast",
          access: [FEATURE.forecast],
          text: t("nav_production.sub.sub_nav_forecast"),
        },
        {
          route: "optimization",
          access: [FEATURE.optimization],
          text: t("nav_production.sub.sub_nav_optimization"),
        },
        {
          route: "scenario_analysis",
          access: [FEATURE.scenario_analysis],
          text: t("nav_production.sub.sub_nav_scenario_analysis"),
        },
        {
          route: "production_data",
          access: [FEATURE.production_data],
          text: t("nav_production.sub.sub_nav_production_data"),
        },
      ],
    }),
    [t]
  );

  return (
    <>
      <List
        disablePadding
        sx={{
          width: "100%",
          maxWidth: 360,
          backgroundColor: "grey.100",
          overflowY: "auto",
        }}
        data-testid="sidebar"
      >
        <SideBarNestedItem
          key="dash"
          routes={{
            route: "dash",
            text: t("nav_home"),
            access: [FEATURE.home],
            Icon: routes.dash.pageIcon,
            children: [],
          }}
        />
        <SideBarNestedItem key="metering" routes={getMeteringRoutes} />
        <SideBarNestedItem
          key="sales"
          routes={{
            route: "",
            text: t("nav_sales.name"),
            access: [FEATURE.sales],
            Icon: routes.sales.pageIcon,
            children: [
              {
                route: "sales",
                text: t("nav_sales.sub.sub_nav_sale_dashboard"),
                access: [FEATURE.sales],
              },
              {
                route: "pricing",
                access: [FEATURE.pricing],
                text: t("nav_sales.sub.sub_nav_pricing"),
              },
            ],
          }}
        />
        <SideBarNestedItem
          key="distribution"
          routes={{
            route: "",
            text: t("nav_distribution.name"),
            access: [FEATURE.dist],
            Icon: routes.distribution.pageIcon,
            children: [
              {
                route: "distribution",
                text: t("nav_distribution.sub.sub_nav_distribution_dashboard"),
                access: [FEATURE.dist],
              },
              {
                route: "rt_analysis",
                access: [FEATURE.dist_rta, FEATURE.dist_rtsp],
                text: t("nav_distribution.sub.sub_nav_return_temperature_analysis"),
              },
              {
                route: "design_load",
                access: [FEATURE.dist_dload],
                text: t("nav_distribution.sub.sub_nav_design_load"),
              },
            ],
          }}
        />
        <SideBarNestedItem key="production" routes={getProductionRoutes} />
        <SideBarNestedItem
          key="substations"
          routes={{
            route: "substations",
            text: t("nav_data_library"),
            access: [FEATURE.datalibrary],
            Icon: routes.substations.pageIcon,
            children: [],
          }}
        />
        <SideBarNestedItem
          key="mapview"
          routes={{
            route: "mapview",
            text: t("nav_map_view"),
            access: [FEATURE.mapview],
            Icon: routes.mapview.pageIcon,
            children: [],
          }}
        />
        {optionals && <SideBarNestedItem key="optionals" routes={optionalRoutes} />}
      </List>
      <Box display="flex" flexGrow={1} flexShrink={1} />
      <SideBarNestedItem
        key="feedback"
        routes={{
          route: "",
          text: t("nav_feedback"),
          // eslint-disable-next-line react/no-unstable-nested-components
          Icon: FeedbackIcon,
          access: [],
          children: [],
          cb: () => {
            ui.closeDrawerOnSmallScreen();
            window.open("mailto:feedback@utilifeed.com?subject=&body=");
          },
        }}
      />
      <SideBarNestedItem
        key="support"
        routes={{
          route: "",
          text: t("nav_support"),
          // eslint-disable-next-line react/no-unstable-nested-components
          Icon: SupportIcon,
          access: [],
          children: [],
          cb: () => {
            ui.closeDrawerOnSmallScreen();
            window.open("mailto:kundtjanst@utilifeed.com?subject=&body=");
          },
        }}
      />
      <SideBarNestedItem
        key="profile"
        routes={{
          route: "",
          text: t("nav_profile_and_settings.name"),
          access: [FEATURE.profile],
          Icon: routes.profile.pageIcon,
          children: [
            {
              route: "profile",
              text: t("nav_profile_and_settings.sub.sub_nav_profile"),
              access: [FEATURE.profile],
            },
            {
              route: "settings",
              access: [FEATURE.profile],
              text: t("nav_profile_and_settings.sub.sub_nav_setting"),
            },
            {
              route: "about",
              access: [],
              text: "About",
            },
            {
              route: "logout",
              access: [],
              text: t("nav_profile_and_settings.sub.sub_nav_logout"),
              cb: () => {
                session.logout();
              },
            },
          ],
        }}
      />
      <Typography
        variant="caption"
        // Bottom right corner
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          fontSize: "0.7rem",
          color: "grey.800",
        }}
      >
        {`v${process.env.APP_VERSION}`}
      </Typography>
    </>
  );
}
