/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/naming-convention */
import { action, flow, makeObservable, observable, ObservableSet, toJS, when } from "mobx";
import { DateTime } from "luxon";

import {
  blkReader,
  EP_SIMULATION_BLOCK,
  NETWORK_BLOCK_TYPES,
  SUBSTATION_BLOCK_TYPES as SBT,
  SUBSTATION_BLOCK_TYPES,
  transformMeterDataToV3Block,
  WEATHER_BLOCK,
} from "@conf/blocks";
import { splitDf, splitDfV4 } from "@core/utils/infoblock";

export function blockID({ resource_type, resource_id, filter_by, block_name }) {
  return `${resource_type}$${resource_id}$${filter_by}$${block_name}`;
}

class Api {
  is_fetching = false;

  request_lock_infoblock = [];

  request_lock_meter_data = [];

  request_lock_weather_data = [];

  request_lock_epo = [];

  available_blocks = new ObservableSet();

  getRequestLock = flow(function* (lockattr) {
    const uuid = `${Math.random()}${Math.random()}`;
    this[`request_lock_${lockattr}`].push(uuid);
    yield when(
      () =>
        this[`request_lock_${lockattr}`].length > 0 && this[`request_lock_${lockattr}`][0] === uuid
    );
    return uuid;
  });

  getMeterDataV4 = flow(function* ({
    network_id,
    resource_uid,
    resource_type = "substation",
    stage = "clean",
    meter = "primary",
    components = [],
    flags = [],
    range = {},
    ts_end = null,
    ts_start = null,
    faults = false,
  }) {
    try {
      if (resource_type === "cluster") {
        const resp = yield this.parent.utfapi.getClusterMeterDataV4({
          resource_uids: [resource_uid],
          network_uid: network_id,
          stage,
          meter,
          components,
          flags,
          range,
          ts_end,
          ts_start,
        });
        return transformMeterDataToV3Block({ resp, ts_start, ts_end, components });
      }
      if (resource_type === "substation") {
        const resp = yield this.parent.utfapi.getSubstationMeterDataV4({
          resource_uids: [resource_uid],
          network_uid: network_id,
          stage,
          meter,
          components,
          flags,
          range,
          ts_end,
          ts_start,
        });
        return transformMeterDataToV3Block({ resp, ts_start, ts_end, components, faults });
      }
    } catch (err) {
      console.log("unable to get meterdata", err);
    }
  });

  getInfoBlocksV4 = flow(async function* ({
    resource_type,
    network_id,
    resource_id,
    block_names,
    block_definitions,
  }) {
    /*
        block definitions is mapping of block_name to block definitions we want procedure to use.
        */
    const request_lock = yield this.getRequestLock("infoblock");
    let blk_def = block_definitions;
    try {
      const pending_blocks = this.getPendingBlocks({
        resource_type,
        resource_id,
        filter_by: "",
        block_names,
      });
      if (pending_blocks.length > 0) {
        let data = null;
        if (resource_type === "network") {
          data = yield this.parent.utfapi.getNetworkInfoBlockV4({
            resource_ids: [resource_id],
            block_names: pending_blocks,
          });
          blk_def = blk_def || NETWORK_BLOCK_TYPES;
        }
        if (resource_type === "network_substations") {
          // fetch substations for adding substationUID /Name to info_block data customer
          data = await this.parent.utfapi.getNetworkSubstationInfoBlockV4({
            network_uid: resource_id,
            block_names: pending_blocks,
          });
          blk_def = blk_def || SUBSTATION_BLOCK_TYPES;
        }
        if (resource_type === "cluster_substations") {
          data = yield this.parent.utfapi.getClusterSubstationInfoBlockV4({
            cluster_uid: resource_id,
            block_names: pending_blocks,
          });
          blk_def = blk_def || SUBSTATION_BLOCK_TYPES;
        }
        if (resource_type === "substation") {
          data = yield this.parent.utfapi.getNetworkSubstationInfoBlockV4({
            network_uid: network_id,
            resource_uids: [resource_id], // ? There is no way to get info block for multiple substations, that arent all substations of a network
            block_names: pending_blocks,
          });
          blk_def = blk_def || SUBSTATION_BLOCK_TYPES;
        }
        if (resource_type === "cluster") {
          data = yield this.parent.utfapi.getNetworkClusterInfoBlockV4({
            network_uid: network_id,
            resource_uids: [resource_id],
            block_names: pending_blocks,
          });
          blk_def = blk_def || SUBSTATION_BLOCK_TYPES;
        }
        if (data && data.collection) {
          const blocks = splitDfV4(data.collection, blk_def);
          this.updateCache({ resource_type, resource_id, filter_by: "", blocks });
        }
      }
      return this.getInfoBlocksFromCache({
        resource_type,
        resource_id,
        filter_by: "",
        block_names,
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.releaseRequestLock("infoblock", request_lock);
    }
  });

  getWeatherDataV4 = flow(function* ({
    resource_id,
    resource_type,
    network_id,
    date_min,
    coordinates,
    date_max,
  }) {
    let latitude_longitude = coordinates;
    try {
      if (
        (latitude_longitude === null || latitude_longitude === undefined) &&
        network_id !== null
      ) {
        const infoResp = yield this.getInfoBlocksV4({
          resource_id,
          resource_type,
          network_id,
          block_names: [SBT.location.to_block_name()],
        });
        const infoRespReader = blkReader(infoResp, [
          ["name", [SBT.location.to_block_name(), SBT.location.col.weather_coordinates], "coords"],
        ]);
        latitude_longitude = infoRespReader(resource_id).coords;
      }
      if (latitude_longitude) {
        const weather_raw_df = yield this.parent.utfapi.getWeatherData({
          coordinate: {
            lat: latitude_longitude[0],
            lon: latitude_longitude[1],
          },
          start_date: date_min,
          end_date: date_max,
        });

        const processed_df = splitDf(weather_raw_df, { weather: WEATHER_BLOCK });
        return processed_df[0][1];
      }
      console.log("unable to get weather data , coordinates not available");
      return null;
    } catch (err) {
      console.log("err", err);
    }
  });

  getEPOData = flow(function* ({
    resource_type,
    resource_id,
    mt_start_date,
    mt_end_date,
    cs_start_date,
    cs_end_date,
    meter_type,
    weather_source,
    return_predictors,
    store_models = true,
  }) {
    /*
        interface for getting EP  prediction data.
         */
    try {
      const requestParams = {
        resource_type,
        resource_id,
        mt_start_date,
        mt_end_date,
        cs_start_date,
        cs_end_date,
        network_name: this.parent.networks.current_network.name,
        store_models,
      };
      if (meter_type !== undefined) {
        requestParams.meter_type = meter_type;
      }
      if (weather_source !== undefined) {
        requestParams.weather_source = weather_source;
      }
      if (return_predictors !== undefined) {
        requestParams.return_predictors = return_predictors;
      }
      const rawdata = yield this.parent.utfapi.getEPDataAsync(requestParams);
      const blocks = splitDf(rawdata, { ep_simulation: EP_SIMULATION_BLOCK });
      return { ep_simulation: blocks[0][1] };
    } catch (err) {
      console.log("got error while fetching EPO data", err);
      throw err;
    }
  });

  getOptimizationStatus = flow(function* () {
    return yield this.parent.utfapi.getOptimizationStatus();
  });

  getOptimizationResult = flow(function* () {
    return yield this.parent.utfapi.getOptimizationResult();
  });

  constructor(parent) {
    makeObservable(this, {
      getRequestLock: true,
      getMeterDataV4: true,
      getInfoBlocksV4: false,
      getWeatherDataV4: true,
      getEPOData: true,
      getOptimizationStatus: true,
      getOptimizationResult: true,
      shouldStore: true,
      saveToCache: true,
      getPendingBlocks: true,
      updateCache: true,
      getInfoBlocksFromCache: true,
      trimCache: true,
      is_fetching: observable,
      request_lock_infoblock: observable,
      request_lock_meter_data: observable,
      request_lock_weather_data: observable,
      request_lock_epo: observable,
      available_blocks: observable,
      loadFromCache: action.bound,
      releaseRequestLock: action.bound,
    });

    this.parent = parent;
    this.blocks = new Map();
    this.blocks_expiration = new Map();
    this.block_usage = new Map();
  }

  shouldStore() {
    return this.available_blocks.size;
  }

  loadFromCache() {
    let blknames = localStorage.getItem("blocks");
    if (blknames !== null) {
      blknames = blknames.split(",");
    } else {
      return;
    }
    const loadedBlknames = [];
    for (const blkid of blknames) {
      let blkj = localStorage.getItem(blkid);
      if (blkj !== null) {
        blkj = JSON.parse(blkj);
        const idxColId = blkj.columns[blkj.idx].idx;
        blkj.idxMap = new Map(blkj.data.map((row, idx) => [row[idxColId], idx]));
        this.blocks.set(blkid, blkj);
        loadedBlknames.push(blkid);
      }
    }
    this.available_blocks = new Set(loadedBlknames);
  }

  saveToCache() {
    try {
      for (const blkName of this.available_blocks) {
        localStorage.setItem(blkName, JSON.stringify(toJS(this.blocks.get(blkName))));
      }
      localStorage.setItem("blocks", Array.from(this.available_blocks.entries()).join(","));
    } catch (err) {
      console.log("space full unable to store", err);
    }
  }

  releaseRequestLock(lockattr, lockId) {
    if (this[`request_lock_${lockattr}`][0] === lockId) {
      this[`request_lock_${lockattr}`].shift();
    }
  }

  getPendingBlocks({ resource_type, resource_id, filter_by, block_names }) {
    const pending_blocks = [];
    let tmpBlkId = null;
    for (const block_name of block_names) {
      tmpBlkId = blockID({ resource_type, resource_id, filter_by, block_name });
      if (!this.available_blocks.has(tmpBlkId)) {
        pending_blocks.push(block_name);
      }
    }
    return pending_blocks;
  }

  updateCache({ resource_type, resource_id, filter_by, blocks }) {
    for (const [block_name, block] of blocks) {
      const tmpBlkId = blockID({ resource_type, resource_id, filter_by, block_name });
      this.blocks_expiration.set(tmpBlkId, DateTime.utc());
      this.blocks.set(tmpBlkId, block);
      this.available_blocks.add(tmpBlkId);
    }
  }

  getInfoBlocksFromCache({ resource_type, resource_id, filter_by, block_names }) {
    const blocks = {};
    for (const block_name of block_names) {
      const tmpBlkId = blockID({ resource_type, resource_id, filter_by, block_name });
      if (this.available_blocks.has(tmpBlkId)) {
        blocks[block_name] = this.blocks.get(tmpBlkId);
        this.blocks_expiration.set(tmpBlkId, DateTime.utc());
      }
    }
    this.trimCache();
    return blocks;
  }

  trimCache(save = 1000) {
    if (this.blocks.size > save) {
      const expirations = Array.from(this.blocks_expiration).sort((a, b) => a[1] - b[1]);
      const tbd = [];
      for (let i = save; i < expirations.length; i++) {
        tbd.push(expirations[i][0]);
      }
      for (const blkId of tbd) {
        this.blocks.delete(blkId);
        this.blocks_expiration.delete(blkId);
        this.available_blocks.delete(blkId);
      }
    }
  }
}

export default Api;
