import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useTheme } from "@mui/material/styles";
import { YAxisOptions } from "highcharts";
import { DateTime } from "luxon";

import { useMeterSeries } from "@utilifeed/hooks";
import { TOOLBOX_OPTION_GRAPH_GRID } from "@config/chartToolbox";
import {
  DATE_FORMAT,
  DATETIME_FORMAT_DEFAULT,
  DATETIME_FORMAT_DISPLAY,
  HOUR_MILIS,
  TIME_FORMAT,
} from "@config/constants";
import { formatNumberForLocale } from "@core/utils";
import { createSeriesToggleHandler } from "@core/utils/createSeriesToggleHandler";
import {
  colorMapping,
  FaultType,
  VALID_FLAGS,
} from "@pages/MeteringDash/FaultDetection/getFaultLevel";
import { ChartToolbox, GraphContainer, HighchartsBase } from "@shared/ui/analytics/charts";
import { generateToolboxAnnotations } from "@shared/ui/analytics/charts/utils";
import withErrorBoundary from "@shared/ui/withErrorBoundary";

import { useConsumption } from "./Consumption.store";
import { consumptionLogger } from "./utils";

const logger = consumptionLogger.getSubLogger({ name: "<MeterData/>" });

const chartHeight = 550;

export const formatFlagsToHighcharts = (
  flags: { from: string | number; to: number; flag: FaultType }[],
  chartData: { series: any; yAxis?: YAxisOptions[] }
) => {
  return flags.map((flag: { from: string | number; to: number; flag: FaultType }) => ({
    ...flag,
    from: chartData.series[1].timeStamp[flag.from],
    to: chartData.series[1].timeStamp[flag.to],
    color: colorMapping[flag.flag].color,
    borderColor: colorMapping[flag.flag].borderColor,
    borderWidth: 1,
    label: {
      useHTML: true,
      text: `<svg 
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium" focusable="false" aria-hidden="true" 
            viewBox="0 0 24 24"
            data-testid="FlagIcon"
            style="color: inherit; fill: inherit; display: block; width: 2em; height: 2em;"
          >
            <path d="M14.4 6 14 4H5v17h2v-7h5.6l.4 2h7V6z" style="color: inherit; fill: inherit;"></path>
          </svg>`,
      style: {
        color: colorMapping[flag.flag].flagColor,
        fill: colorMapping[flag.flag].flagColor,
        fontSize: "12px",
      },
      // Idk, the flags shift the further right they are, so we need to adjust the x position
      x: -4 * (flag.to / 100),
      y: 12,
    },
  }));
};

export const MeterData = withErrorBoundary(
  observer(({ isFaultDetection }) => {
    const { t } = useTranslation(["extendView"]);
    const {
      extremes,
      flowLimiter,
      hasPartialData,
      averaged,
      registerChart,
      chartWidth,
      readerRange,
      seriesInExtremes,
      faults,
    } = useConsumption();
    const theme = useTheme();
    const meterChartRef = useRef();
    const [meterDataToolbox, setMeterDataToolbox] = useState([]);
    const { setVisibleSeries, chartData } = useMeterSeries(
      seriesInExtremes,
      averaged,
      readerRange,
      chartHeight,
      flowLimiter
    );

    useEffect(() => {
      if (!hasPartialData) return;
      registerChart("primary", meterChartRef);
    }, [hasPartialData, registerChart]);

    // Prepare export data for instant CSV and XLSX exports
    const exportData: (number | undefined)[][] = [];
    const seriesToExport = chartData.series.filter((seriesItem) => seriesItem.visible);
    exportData[0] = ["DateTime", ...seriesToExport.map((seriesItem) => seriesItem.name)];

    seriesToExport.forEach((seriesItem, seriesIndex) => {
      seriesItem.data.forEach((seriesData, dataIndex) => {
        const rowIndex = dataIndex + 1;
        if (!exportData[rowIndex]) exportData[rowIndex] = [];
        exportData[rowIndex][0] = DateTime.fromMillis(seriesItem.timeStamp[dataIndex]).toFormat(
          averaged ? DATE_FORMAT : DATETIME_FORMAT_DISPLAY
        );
        exportData[rowIndex][seriesIndex + 1] = seriesData;
      });
    });
    const exportFileName = `${extremes.start?.year}-${extremes.start?.month} to ${extremes.end?.year}-${extremes.end?.month}`;
    const chartId = "meter";

    logger.trace("RENDER");

    const flagsPlotBands = useMemo(() => {
      if (!faults || !faults.length || !isFaultDetection) return null;
      const parsedFlags: Array<{ from: number; to: number; flag: FaultType }> = [];

      // The flags array is an arrary of hours, each our contains an array with possible flags
      // We build up the parsedFlags array with objects that contain a from and to property
      // from and to are just indexes in the series data array ie small numbers

      // Slight complication is that we need to group consecutive flags into one object
      // so we can draw a single flag for all of them
      // Also, an hour can contain multiple flags

      let currentObject: { from: number; to: number; flag: FaultType } | null = null;
      VALID_FLAGS.forEach((flag) => {
        currentObject = null;

        faults.forEach((hourFlags, hourIndex) => {
          if (!hourFlags || !hourFlags.length) {
            currentObject = null;
            return;
          }

          hourFlags.forEach((singleFlag) => {
            if (singleFlag === flag) {
              if (!currentObject) {
                currentObject = { from: hourIndex - 1, to: hourIndex + 1, flag };
                parsedFlags.push(currentObject);
              } else {
                currentObject.to = hourIndex + 1;
              }
            } else {
              currentObject = null;
            }
          });
        });
      });

      // Now we map to the ts defined in chartData
      return formatFlagsToHighcharts(parsedFlags, chartData);
      // dont include chartData, because faults is calculated from chartData
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [faults, isFaultDetection]);

    return (
      <GraphContainer
        title={t("text_meter_data")}
        hasData
        subTitles={
          <ChartToolbox
            values={meterDataToolbox}
            setValues={setMeterDataToolbox}
            disabled={!hasPartialData}
            data-testid={`${chartId}-toolbox`}
          />
        }
        data-testid={`chart-${chartId}-container`}
      >
        <HighchartsBase
          constructorType="stockChart"
          ref={meterChartRef}
          loading={!hasPartialData}
          showGraphUnit={false}
          chart={{
            id: chartId,
            height: `${chartHeight}px`,
            width: chartWidth,
          }}
          annotations={generateToolboxAnnotations({
            series: chartData.series,
            options: meterDataToolbox,
          })}
          series={chartData.series}
          plotOptions={{
            series: {
              dataGrouping: {
                units: [
                  ["hour", [1, 3, 6, 12]],
                  ["day", [1]],
                  ["month", [1, 3, 6]],
                  ["year", null],
                ],
                forced: averaged ? true : undefined,
              },
              connectNulls: true,
              marker: {
                enabled: undefined, // This needs to be undefined to use the default value
                enabledThreshold: 2, // Enable if the distance between points is less than 2 x radius
                radius: 3,
              },
              events: {
                legendItemClick: createSeriesToggleHandler(setVisibleSeries),
              },
            },
          }}
          rangeSelector={{
            enabled: false,
          }}
          legend={{
            enabled: true,
            layout: "horizontal",
            align: "left",
            verticalAlign: "top",
            y: 0,
            labelFormatter() {
              const { color, name, userOptions } = this;
              return `<div data-testid="${chartId}__legend__${userOptions.id}" style="border-bottom: 3px solid ${color}">${name}</div>`;
            },
            itemStyle: {
              ...theme.typography.body2,
            },
          }}
          yAxis={chartData.yAxis}
          xAxis={{
            type: "datetime",
            labelFormatType: averaged ? "date" : "dateOrTime",
            gridLineWidth: meterDataToolbox.includes(TOOLBOX_OPTION_GRAPH_GRID) ? 1 : 0,
            tickInterval: null,
            minRange: HOUR_MILIS, // 1h min range for a better zoom in
            plotBands: flagsPlotBands,
          }}
          navigator={{
            enabled: false,
          }}
          scrollbar={{
            enabled: false,
          }}
          tooltip={{
            split: true,
            formatter() {
              const x = DateTime.fromMillis(this.points[0].x).toFormat(
                averaged ? DATE_FORMAT : DATETIME_FORMAT_DEFAULT
              );
              const k = [`<b>${x}</b>`];
              this.points.forEach(
                (point: {
                  series: {
                    tooltipOptions: { valueSuffix: any };
                    yData: { [x: string]: any };
                    xData: string | any[];
                    name: any;
                  };
                  x: any;
                  y: any;
                }) => {
                  const unit = point.series.tooltipOptions.valueSuffix;
                  const exactPointVal =
                    point.series.yData[point.series.xData.indexOf(point.x)] || point.y;
                  const valY = formatNumberForLocale(exactPointVal);
                  k.push(`<b> ${point.series.name} ${valY} ${unit}</b>`);
                }
              );
              return k;
            },
          }}
          exportData={exportData}
          exporting={{
            filename: exportFileName,
          }}
        />
      </GraphContainer>
    );
  })
);

MeterData.displayName = "Consumption.MeterData";
